import $f from "../components/lib";

export const fetchAllUsers = async ({ queryKey }) => {
  const [_key, { page }] = queryKey;

  return $f
    .httpApiCall({
      url: `/api/users/${page}/20`,
    })
    .then((res) => ({
      data: res.data.content,
      pagination: res.pagination,
    }));
};

export const fetchUserInfo = async ({ queryKey }) => {
  const [_key, { userId }] = queryKey;

  return $f
    .httpApiCall({
      url: `/api/user/${userId}`,
    })
    .then((res) => res);
};

export const updateUser = async (payload) => {
  return $f
    .httpApiCall({
      url: `/api/user`,
      method: "PUT",
      body: JSON.stringify(payload),
    })
    .then((res) => res);
};

export const deleteUser = async ({ userId }) => {
  return $f
    .httpApiCall({
      url: "/api/user/".concat(userId),
      method: "DELETE",
      // body: JSON.stringify([]),
    })
    .then((res) => res);
};
