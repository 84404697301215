import { useState } from "react";
import { Link } from "react-router-dom";
import NoResults from "../../components/NoResults";
import Header from "../../components/Header";
import $f from "../../components/lib";
import Card from "../../components/Card";
import Table from "../../components/Table";
import { useHistory } from "react-router-dom";
import { fetchAllUsers } from "../../apis/users";
import { useMutation, useQuery } from "react-query";
import Pagination from "../../components/pagination";
import { deleteSite } from "../../apis/sites";
import DeleteClient from "../../components/WithModal/DeleteClient";

function Clients() {
  const [page, setPage] = useState(0);
  const history = useHistory();
  const {
    data: users,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["users", { page }],
    queryFn: fetchAllUsers,
  });

  const moreActions = (row) => [
    // {
    //   label: "Deactivate",
    //   id: "DEACTIVATE",
    //   onClick: () => {},
    //   canIsee: row.activated,
    // },
    // {
    //   label: "Activate",
    //   id: "DEACTIVATE",
    //   canIsee: !row.activated,
    //   onClick: () => {},
    // },
    {
      label: "Delete",
      id: "DELETE",
      onClick: (row) => {
        window["modal"].setState({
          show: true,
          body: <DeleteClient refetch={refetch} client={row}></DeleteClient>,
        });
      },
      canIsee: true,
      style: {
        color: "#FF0000",
      },
    },
  ];

  const tableHeaders = [
    {
      label: "Name",
      id: "name",
      th: {
        className: "ps-0",
        style: {
          width: "15%",
        },
      },
      td: { className: "align-middle ps-0 py-3" },
      formatValue: (row) => {
        const { name, surname, userId } = row;
        const _name = name ?? "";
        const _surname = surname ?? "";
        const fullName = _name + " " + _surname;
        const _fullName = fullName.trim() === "" ? "-" : fullName;
        return _fullName;
      },
    },
    {
      label: "Email",
      id: "email",
      td: { className: "align-middle ps-0 py-3" },
      th: {
        className: "ps-0 ",
      },
    },
    {
      label: "Activated",
      id: "activated",
      td: { className: "align-middle ps-0 py-3" },
      th: {
        className: "ps-0 ",
      },
      formatValue: (row) => {
        if (row?.activated) {
          return "ACTIVE";
        } else {
          return "NOT ACTIVE";
        }
      },
    },
    {
      label: "Status",
      id: "status",
      td: { className: "align-middle ps-0 py-3" },
      th: {
        className: "ps-0 ",
      },
    },
    {
      label: "Launch date",
      id: "activationDate",
      th: {
        className: "ps-0 ",
        style: {
          width: "15%",
        },
      },
      td: { className: "ps-0  align-middle py-3" },
      formatValue: (row) => {
        const { activationDate } = row;
        return activationDate ? $f.formatDate(activationDate) : "-";
      },
    },
    {
      label: "",
      id: "actions",
      td: { className: "align-middle text-end ps-5 pe-0 py-2 " },
      formatValue: (row) => {
        return (
          <div className="d-flex justify-content-end gap-2">
            <button
              type="button"
              style={{ borderRadius: 10 }}
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="btn hoverable-lightgray text-grayblack border bg-white btn-sm px-2 py-1"
            >
              <i className="fas fa-ellipsis-h"></i>
            </button>
            <ul
              className="dropdown-menu overflow-hidden pt-0 pb-0 mt-3 mb-3 "
              aria-labelledby="dropdownMenuButton1"
            >
              <ul className="nav flex-column ">
                {moreActions(row).map((item) => {
                  return (
                    item.canIsee && (
                      <li
                        key={item.id}
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          ...item.style,
                        }}
                        className="nav-item d-block action-links  small dropdown-item"
                        role="button"
                        onClick={() => {
                          item?.onClick({ ...item, ...row });
                        }}
                      >
                        {item.label}
                      </li>
                    )
                  );
                })}
              </ul>
            </ul>
          </div>
        );
      },
    },
  ];

  const disableRecord = (row) => {
    if (row.activated === false) {
      return "text-muted disable-tr-row";
    }
  };

  const onPageClick = (p) => {
    setPage(p);
    window.scroll(0, 0);
  };

  return (
    <>
      <Header
        style={{ marginBottom: 30 }}
        title="Clients"
        description="In this page you can view your registered clients"
      />
      <div className="mt-4">
        <div className="row">
          <div className="col">
            <Card
              style={{
                minHeight: "80vh",
                borderRadius: 15,
              }}
              pagesError
              loading={isLoading}
              error={isError}
              bodyClassName="card-body d-flex flex-column flex-fill h-100"
            >
              {users?.data?.length > 0 ? (
                <Table
                  rowClassName={disableRecord}
                  headers={tableHeaders}
                  data={users.data}
                />
              ) : (
                <NoResults
                  subtitle={
                    <div className="d-flex align-items-start justify-content-start  flex-column">
                      <p className="m-0" style={{ fontSize: 18 }}>
                        <strong>There are not any clients yet</strong>
                      </p>
                    </div>
                  }
                />
              )}
              <div>
                <Pagination
                  totalRecords={users?.pagination?.total}
                  offset={20}
                  onPageClick={onPageClick}
                  current={users?.pagination?.self}
                  key={Math.random(4)}
                ></Pagination>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clients;
