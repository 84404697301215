import KeywordsDetails from "./pages/Reports/Keywords/KeywordsDetails";
import TagInfoDetails from "./pages/Reports/TagInfo/TagInfoDetails";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Home from "./pages/Home";
import Site from "./pages/Site/Home";
import AllPages from "./pages/Site/AllPages";
import SitePage from "./pages/Site/Page";
import Sites from "./pages/Sites";
import Reports from "./pages/Reports";
import GoogleReports from "./pages/Reports/GoogleReports";
import AddNewSite from "./pages/AddNewSite";
import Billing from "./pages/Billing";
import Invoices from "./pages/Invoices";
import InvoiceDetails from "./pages/Invoices/InvoiceDetails/Index";
import NotFound404 from "./pages/NotFound404";
import SuccessRegistration from "./pages/Auth/Activate/Success";
import Activate from "./pages/Auth/Activate";
import ImportPages from "./pages/ImportPages";
import Clients from "./pages/Clients";
import ClientSites from "./pages/Clients/Sites";

export const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
    guards: [],
    name: "HOME",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/add-new-site",
    component: AddNewSite,
    exact: true,
    guards: [],
    name: "ADD_NEW_SITE",
    hasSideBar: false,
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/import-pages/:siteId",
    component: ImportPages,
    exact: true,
    guards: [],
    name: "IMPORT_PAGES",
    hasSideBar: false,
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  // {
  //   path: "/billing",
  //   component: Billing,
  //   exact: true,
  //   guards: [],
  //   name: "BILLING",
  //   beforeEnter: (payload) => {
  //     const { history, isAuth } = payload;
  //     if (!isAuth) {
  //       history.replace("/login");
  //     }
  //   },
  // },
  // {
  //   path: "/invoices",
  //   component: Invoices,
  //   exact: true,
  //   guards: [],
  //   name: "INVOICES",
  //   beforeEnter: (payload) => {
  //     const { history, isAuth } = payload;
  //     if (!isAuth) {
  //       history.replace("/login");
  //     }
  //   },
  // },
  {
    path: "/clients",
    component: Clients,
    exact: true,
    guards: [],
    name: "TEAM_MANAGEMENT",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  // {
  //   path: "/client/:clientId/sites",
  //   component: ClientSites,
  //   exact: true,
  //   guards: [],
  //   name: "TEAM_MANAGEMENT",
  //   beforeEnter: (payload) => {
  //     const { history, isAuth } = payload;
  //     if (!isAuth) {
  //       history.replace("/login");
  //     }
  //   },
  // },
  // {
  //   path: "/invoice-details/:invoiceId",
  //   component: InvoiceDetails,
  //   exact: true,
  //   guards: [],
  //   name: "INVOICE_DETAILS",
  //   beforeEnter: (payload) => {
  //     const { history, isAuth } = payload;
  //     if (!isAuth) {
  //       history.replace("/login");
  //     }
  //   },
  // },
  {
    path: "/sites",
    component: Sites,
    exact: true,
    guards: [],
    name: "SITES",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/site/:siteId",
    component: Site,
    exact: true,
    guards: [],
    name: "SITE",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/site/:siteId/pages",
    component: AllPages,
    exact: true,
    guards: [],
    name: "ALL_SITE_PAGES",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/site/:siteId/page/:pageId",
    component: SitePage,
    exact: true,
    guards: [],
    name: "SITE_PAGE",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/site/:siteId/page/:pageId/reports",
    component: Reports,
    exact: true,
    guards: [],
    name: "PAGE_REPORT",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/site/:siteId/:pageId?/reports/queries",
    component: () => <GoogleReports type="queries" />,
    exact: true,
    guards: [],
    name: "VIEW_ALL_QUERIES",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/site/:siteId/:pageId?/reports/visits",
    component: () => <GoogleReports type="visits" />,
    exact: true,
    guards: [],
    name: "VIEW_ALL_COUNTRY_VISITS",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/site/:siteId/keywords/:keywordId",
    component: KeywordsDetails,
    exact: true,
    name: "KEYWORDS_DETAILS",
    guards: [],
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/site/:siteId/page/:pageId/tag/:tagId/:version",
    component: TagInfoDetails,
    exact: true,
    name: "TAG_INFO_DETAILS",
    guards: [],
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/site/:siteId/reports",
    component: Reports,
    exact: true,
    guards: [],
    name: "SITE_REPORT",
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (!isAuth) {
        history.replace("/login");
      }
    },
  },
  {
    path: "/login",
    component: Login,
    exact: true,
    name: "LOGIN",
    guards: [],
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (isAuth) {
        history.replace("/");
      }
    },
  },
  {
    path: "/activate/:token",
    component: Activate,
    exact: true,
    name: "ACTIVATE",
    guards: [],
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (isAuth) {
        history.replace("/");
      }
    },
  },
  {
    path: "/error",
    component: NotFound404,
    exact: true,
    name: "ERROR_PAGE",
    hasSideBar: false,
    guards: [],
  },
  {
    path: "/register",
    component: Register,
    exact: true,
    name: "REGISTER",
    guards: [],
    beforeEnter: (payload) => {
      const { history, isAuth } = payload;
      if (isAuth) {
        history.replace("/");
      }
    },
  },
];
