import { useState } from "react";
import Header from "../../../components/Header";
import Card from "../../../components/Card";
import Table from "../../../components/Table";
import NoResults from "../../../components/NoResults";
import { useParams } from "react-router-dom";
import Pagination from "../../../components/pagination";
import { Link } from "react-router-dom";
import { fetchSite, fetchSitePages } from "../../../apis/sites";
import { useQuery } from "react-query";
import EditPageModal from "../../../components/WithModal/EditPage";

import LoadingOverlayScreen from "../../../components/LoadingOverlayScreen";
import { useActions } from "../../../hooks/useActions";
import Invitation from "../../../components/WithModal/Invitation";
import { useHistory } from "react-router-dom";
import ImportPagesButton from "../../../components/ImportPagesButton";
import { usePrivileges } from "../../../hooks/usePrivileges";

function AllPages() {
  const [open, setOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [page, setPage] = useState(0);
  const { siteId } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [pageUrl, setPageUrl] = useState(undefined);
  const { data: site } = useQuery(["site", { siteId }], fetchSite);

  const {
    data: pages,
    error: errorMessagePages,
    isError: isPagesError,
    isLoading: isPagesLoading,
  } = useQuery({
    queryKey: ["sitePages", { page, siteId, pageUrl }],
    queryFn: fetchSitePages,
  });

  const { data: pagesData, pagination } = pages ?? {};
  const { siteActions, isDownloading } = useActions();
  const { canIImportPages, canISeeShareButton, canIEditPage, canIDeletePage } =
    usePrivileges();

  const tableHeaders = [
    {
      label: "",
      id: "pageURL",
      type: "link",
      th: { className: "ps-0" },
      td: { className: "ps-0 py-3" },
      formatValue: (row) => {
        const _url = "/site/".concat(siteId).concat("/page/").concat(row.id);
        return (
          <Link to={_url}>
            <span
              className="d-inline-block text-truncate"
              style={{ maxWidth: "30rem" }}
            >
              {row.pageURL}
            </span>
          </Link>
        );
      },
    },
    {
      label: "",
      id: "actions",
      td: { className: "align-middle text-end ps-5 pe-0 py-2 " },
      formatValue: (row) => {
        return (
          <div className="d-flex justify-content-end gap-2">
            {canIEditPage(site) && (
              <button
                type="button"
                style={{ borderRadius: 10 }}
                className="btn hoverable-lightgray text-grayblack border bg-white btn-sm px-3 py-1"
                onClick={() => {
                  setOpen(true);
                  setSelectedPage(row);
                }}
              >
                Edit
              </button>
            )}
            {/* {canIDeletePage(site) && (
              <button
                type="button"
                style={{ borderRadius: 10 }}
                className="btn hoverable-lightgray border-danger text-danger bg-white btn-sm  px-3 py-1"
              >
                Delete
              </button>
            )} */}
          </div>
        );
      },
    },
  ];

  const searchPage = () => {
    setPageUrl(searchValue);
  };

  const onPageClick = (p) => {
    setPage(p);
    window.scroll(0, 0);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedPage(null);
  };

  return (
    <>
      {isDownloading && <LoadingOverlayScreen />}
      <EditPageModal
        pageId={selectedPage?.id}
        open={open}
        closeModal={closeModal}
      />
      <Header
        style={{ marginBottom: 30 }}
        title={site?.name || "-"}
        description={`Total Pages: ${site?.totalPages || "-"}`}
        actions={() => {
          return (
            <div className="d-flex justify-content-end gap-2">
              {canISeeShareButton(site) && (
                <button
                  type="button"
                  style={{ borderRadius: 10 }}
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    window["modal"].setState({
                      show: true,
                      body: (
                        <Invitation siteId={siteId} siteName={site?.name} />
                      ),
                    });
                  }}
                >
                  <i class="fas fa-user-friends me-2"></i>
                  <span>Share</span>
                </button>
              )}
              <ImportPagesButton
                siteId={siteId}
                canISee={canIImportPages(site)}
              />
              <>
                <button
                  type="button"
                  style={{ borderRadius: 10 }}
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="btn hoverable-lightgray text-grayblack text-grayblack border bg-white btn-sm px-3 py-1"
                >
                  <i className="fas fa-ellipsis-h"></i>
                </button>
                <ul
                  className="dropdown-menu overflow-hidden pt-0 pb-0 mt-3 mb-3 "
                  aria-labelledby="dropdownMenuButton1"
                >
                  <ul className="nav flex-column ">
                    {siteActions.map(
                      (item) =>
                        item.canISee(site) && (
                          <li
                            key={item.id}
                            style={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              ...item.style,
                            }}
                            className="nav-item d-block action-links  small dropdown-item"
                            role="button"
                            onClick={() =>
                              item.onClick({
                                name: site?.name,
                                projectId: siteId,
                              })
                            }
                          >
                            {item.label}
                          </li>
                        )
                    )}
                  </ul>
                </ul>
              </>
            </div>
          );
        }}
      />
      <div className="mt-4">
        <div className="row">
          <div className="col">
            <Card
              style={{
                ...(pagination?.total === 0 ? { minHeight: "80vh" } : {}),
                borderRadius: 15,
              }}
              pagesError
              loading={isPagesLoading}
              error={isPagesError}
              errorMessage={errorMessagePages?.error}
              bodyClassName="card-body d-flex flex-column flex-fill h-100"
            >
              <div className="row g-2">
                <div className="col-xs-12 col-sm-12 order-2 order-xm-1 order-sm-1 order-md-1 order-lg-1 order-xl-1 col-md-6 col-lg-6 col-xl-6">
                  <strong>URL</strong>
                </div>
                <div className="col-xs-12 col-sm-12 order-1  order-xm-2 order-sm-2 order-md-2 order-lg-2 order-xl-2 col-md-6 col-lg-6 col-xl-6">
                  <div className="d-flex flex-row">
                    <input
                      type="text"
                      name="q"
                      placeholder="Search page"
                      style={{ borderRadius: 12 }}
                      className="form-control py-0 w-100"
                      value={searchValue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchPage();
                        }
                      }}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                    <div
                      className="input-group-prepend"
                      style={{ marginLeft: 3 }}
                    >
                      <button
                        className="btn btn-primary px-3"
                        style={{ borderRadius: 15 }}
                        onClick={searchPage}
                      >
                        <i className="fas fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {pagination?.total > 0 ? (
                <Table headers={tableHeaders} data={pagesData} />
              ) : (
                <NoResults
                  title="There are not any pages yet"
                  subtitle={
                    canIImportPages(site) ? (
                      <Link
                        to="/"
                        style={{ borderRadius: 10 }}
                        className="btn btn-primary btn-sm mt-2"
                      >
                        <span>Import pages</span>
                      </Link>
                    ) : null
                  }
                />
              )}
              <div>
                <Pagination
                  totalRecords={pagination?.total}
                  offset={20}
                  onPageClick={onPageClick}
                  current={pagination?.self}
                  key={Math.random(4)}
                ></Pagination>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllPages;
