import { useAuth } from "../providers/AuthProvider";

export const usePrivileges = () => {
  const { authUser } = useAuth();
  const isSuperAdmin = authUser?.admin;
  const canIImportPages = (args) => {
    const allowedRoles = ["OWNER", "SITE_MANAGER"];
    if (isSuperAdmin || allowedRoles.includes(args?.projectUserRole)) {
      return args?.sitemapMethod === null || args?.sitemapMethod === false;
    } else {
      return false;
    }
  };

  const canISeeShareButton = (args) => {
    const allowedRoles = ["OWNER", "SITE_MANAGER"];
    return isSuperAdmin || allowedRoles.includes(args?.projectUserRole);
  };

  const canIEditPage = (args) => {
    const allowedRoles = ["OWNER", "SITE_MANAGER"];
    return isSuperAdmin || allowedRoles.includes(args?.projectUserRole);
  };

  const canIDeletePage = (args) => {
    const allowedRoles = ["OWNER"];
    return isSuperAdmin || allowedRoles.includes(args?.projectUserRole);
  };

  const canIUploadGoogleIndex = (args) => {
    const allowedRoles = ["OWNER", "SITE_MANAGER"];
    return isSuperAdmin || allowedRoles.includes(args?.projectUserRole);
  };

  const canIIngest = (args) => {
    const allowedRoles = ["OWNER", "SITE_MANAGER"];
    return (
      isSuperAdmin ||
      (allowedRoles.includes(args?.projectUserRole) && args?.totalPages > 0)
    );
  };

  const canIDeleteSite = (args) => {
    const allowedRoles = ["OWNER"];
    return isSuperAdmin || allowedRoles.includes(args?.projectUserRole);
  };

  const canISeeGoogleDownloadJson = (args) => {
    return args?.googleJsonFileName !== null;
  };

  const canISeeDownloadSitemap = (args) => {
    return args?.sitemapMethod === true;
  };

  const canIExportSitePages = (args) => {
    return args?.totalPages > 0;
  };

  const canIEditBulkSitePages = (args) => {
    const allowedRoles = ["OWNER", "SITE_MANAGER"];
    return (
      args?.totalPages > 0 &&
      (isSuperAdmin || allowedRoles.includes(args?.projectUserRole))
    );
  };

  const canIRenameSite = (args) => {
    const allowedRoles = ["OWNER", "SITE_MANAGER"];
    return isSuperAdmin || allowedRoles.includes(args?.projectUserRole);
  };

  return {
    canIImportPages,
    canISeeShareButton,
    canIEditPage,
    canIDeletePage,
    canIUploadGoogleIndex,
    canIIngest,
    canIDeleteSite,
    canISeeGoogleDownloadJson,
    canISeeDownloadSitemap,
    canIExportSitePages,
    canIRenameSite,
    canIEditBulkSitePages,
  };
};
