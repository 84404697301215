import React from "react";
import ModalDialog from "../../ModalDialog";
import { deleteSite } from "../../../apis/sites";
import { useMutation } from "react-query";
import $f from "../../lib";
import { useHistory } from "react-router-dom";
import { deleteUser } from "../../../apis/users";

export default function DeleteClient({ client, refetch }) {
  const history = useHistory();
  const { mutate, status } = useMutation(deleteUser, {
    throwOnError: true,
    onError: () => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
      window["modal"].setState({ show: false });
    },
    onSuccess: () => {
      $f.createNotification({
        message: "Delete was successful!",
        type: "success",
      });
      window["modal"].setState({ show: false });

      refetch();
    },
  });

  const onDelete = () => {
    mutate({ userId: client.userId });
  };

  const isSubmitting = status === "loading";

  return (
    <ModalDialog
      open={true}
      title="Delete User?"
      buttons={[
        {
          title: "Cancel",
          onClick: () => {
            window["modal"].setState({ show: false });
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm me-2",
        },
        {
          title: "Delete",
          onClick: onDelete,
          className: "btn btn-danger btn-sm",
          loading: isSubmitting,
        },
      ]}
    >
      <div className="p-2">
        Are you sure you want to delete the user with the email {client?.email}
        from the platform?
      </div>
    </ModalDialog>
  );
}
